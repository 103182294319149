import {
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonListHeader,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImage,
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonText,
  IonSkeletonText,
  IonThumbnail
}
from '@ionic/react';
import { useParams } from 'react-router';
import './Page.css';
import React, { useRef, useEffect, useState } from "react";
import axios from "axios";

const Page = () => {

  const file = useRef(null);
  const { name } = useParams();
  const [filename, setFilename] = useState(null);
  const [result, setResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);


  useEffect(() => {
    file.current.onchange = () => {
      // file.current.files[0].text().then((result) => {
      //   console.log(result);
      // });
      if (file.current.files.length === 1) {
        submitForm(file.current.files[0]);
      }
    }

    navigator.geolocation.getCurrentPosition(result => {
      console.log(result.coords.latitude, result.coords.longitude);
      setLat(result.coords.latitude);
      setLon(result.coords.longitude);
    }, error => console.error(error));

  }, []);

  useEffect(() => {
    setResult(null);
    setErrorMessage(null);
  }, [name])

  const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
    });
  };

  const submitForm = (file) => {
    console.log(lat, lon, result);
    //const url = "https://ecwcmm5kbh.execute-api.us-east-1.amazonaws.com/mock?" +
    const url = "https://yygi0lb3o7.execute-api.us-east-1.amazonaws.com/match?" +
      "mode=" + name +
      "&uid=" + new Date().getTime() +
      "&lat=" + lat +
      "&lon=" + lon;
    blobToBase64(file.slice()).then((data) => {
      axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          setResult(res.data);
        })
        .catch((err) => {
          setResult(null);
          console.log(err);
          setErrorMessage(err.name + ": " + err.message);
        });
    });
    setResult({});
    setErrorMessage(null);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle><span style={{fontSize: "125%"}}>{name==="lost"? '🐾' : '🐶'}</span>&nbsp;&nbsp;<span style={{textTransform:'capitalize'}}>{name}</span> Dogs</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList  style={{paddingLeft: "50px", paddingRight: "50px"}}>
          <IonListHeader><h3>Upload a photo of the dog you {name}</h3></IonListHeader>
          <IonItem lines="none">
            <IonButton size="large" expand="full" shape="round" style={{width:"100%"}} className="fileUpload" >
              <span>Upload</span>
              <input ref={file} type="file" className="upload" />
            </IonButton>
          </IonItem>
       </IonList>
       <div style={{paddingLeft: "50px", paddingRight: "50px"}}>
        {result && result.total === undefined && 
          <>
            <IonCard style={{maxWidth:"50%", padding:"20px"}} key={result.id}>
              <IonThumbnail>
                <IonSkeletonText animated width="300px" height="300px"/>
              </IonThumbnail>
              <IonCardHeader>
                <IonCardTitle><IonSkeletonText animated width="50%"/></IonCardTitle>
                <IonCardSubtitle><IonSkeletonText animated width="50%"/></IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
              </IonCardContent>
            </IonCard>
          </>
        }
        {result && result.total && 
            <>
            <IonCard style={{maxWidth:"50%"}} key={result.id}>
              <img width="100%" src={result.url} />
              <IonCardHeader>
                <IonCardTitle>Your Photo</IonCardTitle>
                <IonCardSubtitle>{lat} {lon}</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
              </IonCardContent>
            </IonCard>
            </>
        }
        {result && result.total > 0 && <IonListHeader><h3>{result.total} matching photos</h3></IonListHeader>}
        <IonGrid><IonRow>
        {result && result.results && result.results.map(item=>
        <IonCard style={{maxWidth:"50%"}} key={item.id}>
          <img width="100%" src={item.url} />
          <IonCardHeader>
            <IonCardTitle>{item.breed}</IonCardTitle>
            <IonCardSubtitle>{item.lat} {item.lon}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>)
        }
        {errorMessage && <IonListHeader><h2><IonText color="danger">{errorMessage}</IonText></h2></IonListHeader>}
        </IonRow></IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Page;
